// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Inter/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Inter/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Inter/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

/*class for CK Editor (TextArea)*/
.ck-body-wrapper {
  display: none !important;
}

/*class for DatePicker*/
.react-datepicker-wrapper {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA,iCAAiC;AACjC;EACE,wBAAwB;AAC1B;;AAEA,uBAAuB;AACvB;EACE,WAAW;AACb;;AAEA;EACE;aACW;AACb;;AAEA;EACE,4BAA4B;EAC5B;8DAC2D;EAC3D,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;EAC3B;8DAC0D;AAC5D;;AAEA;EACE,yBAAyB;EACzB;8DACwD;EACxD,iBAAiB;AACnB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #ffffff;\n}\n\n/*class for CK Editor (TextArea)*/\n.ck-body-wrapper {\n  display: none !important;\n}\n\n/*class for DatePicker*/\n.react-datepicker-wrapper {\n  width: 100%;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n@font-face {\n  font-family: \"Inter-Regular\";\n  src: local(\"Inter-Regular\"),\n    url(\"./fonts/Inter/Inter-Regular.ttf\") format(\"truetype\");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"Inter-Medium\";\n  src: local(\"Inter-Medium\"),\n    url(\"./fonts/Inter/Inter-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Inter-Bold\";\n  src: local(\"Inter-Bold\"),\n    url(\"./fonts/Inter/Inter-Bold.ttf\") format(\"truetype\");\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
