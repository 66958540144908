import React, { createContext, useContext, useEffect, useState } from "react";
import { ExpiDataResponse } from "../api/dashboardApi";
import { scrollToTop } from "../components/functions/scrollUtils";
import { Tenants } from "../api/authApi";
import mixpanel from "mixpanel-browser";

interface AppContextType {
  isSidebarVisible: boolean;
  isProfileModalVisible: boolean;
  username: string | null;
  gridList: boolean;
  gridListExports: boolean;
  showAdditionalInfoSidebar: boolean;
  eventDetails: EventDetails;
  toggleAdditionalInfoSidebar: (status?: boolean) => void;
  toggleSidebar: () => void;
  toggleProfileModal: (status?: boolean) => void;
  setUser: (
    name?: string,
    avatar?: string,
    isTenant?: boolean,
    token?: string
  ) => void;
  toggleGridList: () => void;
  toggleGridListExports: () => void;
  handleSelectedEvent: (event: ExpiDataResponse) => void;
  toggleEventDetails: () => void;
  isDoziviSrpskuAdmin: () => boolean;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

interface AppProviderProps {
  children: React.ReactNode;
}

type EventDetails = {
  selectedEvent: ExpiDataResponse | null;
  isShowed: boolean;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [gridList, setGridList] = useState(true);
  const [gridListExports, setGridListExports] = useState(true);
  const [username, setUsername] = useState("");
  const [avatarImg, setAvatarImg] = useState("");
  const [showAdditionalInfoSidebar, setShowAdditionalInfoSidebar] =
    useState<boolean>(false);
  const [eventDetails, setEventDetails] = useState<EventDetails>({
    selectedEvent: null,
    isShowed: false,
  });

  useEffect(() => {
    const storedGridListExports = localStorage.getItem("gridListExports");
    const storedGridList = localStorage.getItem("gridList");

    if (storedGridList) {
      setGridList(JSON.parse(storedGridList));
    }
    if (storedGridListExports) {
      setGridListExports(JSON.parse(storedGridListExports));
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const toggleAdditionalInfoSidebar = (
    state: boolean = !showAdditionalInfoSidebar
  ) => {
    setShowAdditionalInfoSidebar(state);
    mixpanel.track(
      "Additional options side bar: Show/Hide additional options",
      { state }
    );
  };

  const toggleProfileModal = (state: boolean = !isProfileModalVisible) => {
    setIsProfileModalVisible(state);
  };

  const toggleGridList = () => {
    setGridList(!gridList);
    localStorage.setItem("gridList", JSON.stringify(!gridList));
    mixpanel.track("List/Grid button clicked", {
      list: gridList,
      grid: !gridList,
    });
  };

  const toggleGridListExports = () => {
    setGridListExports(!gridListExports);
    localStorage.setItem("gridListExports", JSON.stringify(!gridListExports));
  };

  const setUser = (
    name: string = username,
    avatar: string = avatarImg,
    isTenant: boolean = false,
    token?: string
  ) => {
    localStorage.setItem("isTenant", JSON.stringify(isTenant));
    localStorage.setItem("username", name);
    localStorage.setItem("user-image", avatar);
    token && localStorage.setItem("token", token);
    setUsername(name);
    setAvatarImg(avatar);
  };

  const handleSelectedEvent = (event: ExpiDataResponse) => {
    setEventDetails({ isShowed: true, selectedEvent: event });
    mixpanel.track("Show event details clicked");
    scrollToTop();
  };

  const toggleEventDetails = () => {
    setEventDetails((prev) => ({ ...prev, isShowed: !eventDetails.isShowed }));
  };

  const isDoziviSrpskuAdmin = () => {
    const host: string = window.location.host;
    const isAdmin: boolean = JSON.parse(
      localStorage.getItem("isTenant") || "false"
    );
    const isDoziviSrpska: boolean =
      (host.includes(Tenants.DoziviSrpsku) ||
        host.includes(Tenants.SeeSlovenia) ||
        host.includes(Tenants.BosnianOutdoor)) &&
      isAdmin;
    return isDoziviSrpska;
  };

  const contextValue: AppContextType = {
    isSidebarVisible,
    isProfileModalVisible,
    username,
    gridList,
    gridListExports,
    showAdditionalInfoSidebar,
    eventDetails,
    toggleSidebar,
    toggleProfileModal,
    setUser,
    toggleGridList,
    toggleGridListExports,
    toggleAdditionalInfoSidebar,
    handleSelectedEvent,
    toggleEventDetails,
    isDoziviSrpskuAdmin,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
