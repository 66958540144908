import { useFilterContext } from "./FilterContext";

const useFilters = () => {
  const { state, dispatch } = useFilterContext();

  const setFilter = (key: string, value: string | number) => {
    dispatch({ type: "SET_FILTER", payload: { key, value } });
  };

  const setPage = (page: number) => {
    dispatch({ type: "SET_PAGE", payload: page });
  };

  const setPageSize = (pageSize: number) => {
    dispatch({ type: "SET_PAGE_SIZE", payload: pageSize });
  };

  const setTotal = (total: number) => {
    dispatch({ type: "SET_TOTAL", payload: total });
  };

  const setTotalPages = (totalPages: number) => {
    dispatch({ type: "SET_TOTAL_PAGES", payload: totalPages });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  return {
    filters: state,
    setFilter,
    resetFilters,
    setPage,
    setPageSize,
    setTotal,
    setTotalPages,
  };
};

export default useFilters;
