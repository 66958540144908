import React, { createContext, useContext, useEffect, useState } from "react";

interface ImportsContextType {
  file: File | null;
  handleSetFile: (uploadedFile: File | null) => void;
  fields: any[];
  handleSetFields: (uploadedFields: any[]) => void;
  fileId: number | null;
  handleSetFileId: (uploadedFileId: number | null) => void;
  staticFields: any[];
  handleSetStaticFields: (uploadedStaticFields: any[]) => void;
  clearImportsStorage: () => void;
}

const ImportsContext = createContext<ImportsContextType | undefined>(undefined);

export const ImportsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [file, setFile] = useState<File | null>(() => {
    const savedFile = localStorage.getItem("file");
    return savedFile ? JSON.parse(savedFile) : null;
  });

  const [fields, setFields] = useState<any[]>(() => {
    const savedFields = localStorage.getItem("fields");
    return savedFields ? JSON.parse(savedFields) : [];
  });

  const [fileId, setFileId] = useState<number | null>(() => {
    const savedFileId = localStorage.getItem("fileId");
    return savedFileId ? JSON.parse(savedFileId) : null;
  });

  const [staticFields, setStaticFields] = useState<any[]>(() => {
    const savedStaticFields = localStorage.getItem("staticFields");
    return savedStaticFields ? JSON.parse(savedStaticFields) : [];
  });

  const handleSetFile = (uploadeFile: File | null) => {
    setFile(uploadeFile);
    localStorage.setItem("file", JSON.stringify(uploadeFile));
  };

  const handleSetFields = (uploadedFields: any[]) => {
    setFields(uploadedFields);
    localStorage.setItem("fields", JSON.stringify(uploadedFields));
  };

  const handleSetFileId = (uploadedFileId: number | null) => {
    setFileId(uploadedFileId);
    localStorage.setItem("fileId", JSON.stringify(uploadedFileId));
  };

  const handleSetStaticFields = (uploadedStaticFields: any[]) => {
    setStaticFields(uploadedStaticFields);
    localStorage.setItem("staticFields", JSON.stringify(uploadedStaticFields));
  };

  useEffect(() => {
    const savedFile = localStorage.getItem("file");
    const savedFields = localStorage.getItem("fields");
    const savedFileId = localStorage.getItem("fileId");
    const savedStaticFields = localStorage.getItem("staticFields");

    if (savedFile) {
      setFile(JSON.parse(savedFile));
    }

    if (savedFields) {
      setFields(JSON.parse(savedFields));
    }

    if (savedFileId) {
      setFileId(JSON.parse(savedFileId));
    }

    if (savedStaticFields) {
      setStaticFields(JSON.parse(savedStaticFields));
    }
  }, []);

  const clearImportsStorage = () => {
    setFile(null);
    setFields([]);
    setFileId(null);
    setStaticFields([]);
    localStorage.removeItem("file");
    localStorage.removeItem("fields");
    localStorage.removeItem("fileId");
    localStorage.removeItem("staticFields");
  };

  const contextValue: ImportsContextType = {
    file,
    handleSetFile,
    fields,
    handleSetFields,
    fileId,
    handleSetFileId,
    staticFields,
    handleSetStaticFields,
    clearImportsStorage,
  };

  return (
    <ImportsContext.Provider value={contextValue}>
      {children}
    </ImportsContext.Provider>
  );
};

export const useImportsContext = (): ImportsContextType => {
  const context = useContext(ImportsContext);
  if (!context) {
    throw new Error("useImportsContext must be used within an ImportsProvider");
  }
  return context;
};
