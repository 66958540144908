import axios from "axios";

export enum SocialLoginPlatform {
  Google = "google",
  Facebook = "facebook"
}

export enum Tenants {
  DoziviSrpsku = "dozivi",
  SeeSlovenia = "si",
  BosnianOutdoor = "ba"
}

export interface RegisterUserRequest {
  email: string;
  name: string;
  password: string;
  password_repeat: string;
  accepted_terms: boolean;
  recaptcha_token: string | null;
  tenant: string | null;
}

export interface SocialLoginBody {
  token: string;
  platform: SocialLoginPlatform;
  tenant: Tenants | null;
}

export type DecodedToken = {
  email: string;
  exp: number;
  iat: number;
  id: number;
}

const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Version": "1.0.0",
    "Language": localStorage.getItem("language")
  },
});

export const login = async (credentials: {
  email: string;
  password: string;
}) => {
  try {
    const response = await authApi.post("/login", credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const register = async (body: RegisterUserRequest) => {
  try {
    const response = await authApi.post("/register", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const socialLogin = async (body: SocialLoginBody) => {
  try {
    const response = await authApi.post("/social-login",  body);
    return response.data;
  } catch (error) {
    throw(error);
  }
}

export const logout = async () => {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await authApi.post("/logout", {}, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};
